var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showModuloForm === false)?_c('div',[_c('MarketingModules')],1):(_vm.showModuloForm === true)?_c('div',{staticClass:"page"},[_c('Panel',{attrs:{"lineColor":"var(--primary-color)"}},[_c('DataTable',{ref:"datatable",attrs:{"headers":_vm.headers,"endpoint":_vm.endpoint,"sortBy":"numpedido","sortDesc":true,"search":""},on:{"click":_vm.onClick},scopedSlots:_vm._u([{key:"numpedido",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex mb-n7 mt-2 ml-3"},[_c('v-chip',{staticClass:"ml-auto font-weight-bold",attrs:{"color":_vm.getStatusPedidoColor(row.statuspedido),"small":""}},[_vm._v(" "+_vm._s(_vm.getStatusPedido(row.statuspedido))+" ")])],1),_c('strong',[_vm._v(_vm._s(row.numpedido))]),_vm._v(" "),_c('br'),_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(row.datapedido)))]),_vm._v(" "),_c('br'),_c('span',[_vm._v("Total: "+_vm._s(_vm._f("formatMoney")(row.totalliquido)))])]}},{key:"nomecliente",fn:function(ref){
var row = ref.row;
return [_c('strong',[_vm._v(_vm._s(row.nomecliente))]),_vm._v(" "),_c('br'),_c('span',[_vm._v(_vm._s(row.emailcliente))]),_vm._v(" "),_c('br'),_c('span',[_vm._v(_vm._s(_vm.formatTelefone(row.telefonecliente)))])]}},{key:"nota",fn:function(ref){
var col = ref.col;
return [(col)?_c('span',[_c('span',{staticStyle:{"font-size":"2.5em"}},[_vm._v(_vm._s(_vm.avaliacoes[col].smile))]),_c('h4',[_vm._v(_vm._s(_vm.avaliacoes[col].text))])]):_c('span',[_vm._v("Não avaliado")])]}},{key:"comentario",fn:function(ref){
var col = ref.col;
return [(col)?_c('span',[_vm._v(_vm._s(col))]):_c('span',[_vm._v("Sem comentário")])]}},{key:"idpedido",fn:function(ref){
var row = ref.row;
return [_c('FormButtonMenu',{attrs:{"icon":"","options":_vm.tableOptions,"loading":_vm.loadingRow[row.numpedido]},on:{"click":function (op) { return _vm.selectOption(row, op); }}},[_c('v-icon',{attrs:{"size":"15"}},[_vm._v("fas fa-ellipsis-v")])],1)]}}])})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }