<template>
  <div v-if="showModuloForm === false">
    <MarketingModules></MarketingModules>
  </div>
  <div class="page" v-else-if="showModuloForm === true">
    <Panel lineColor="var(--primary-color)">
      <DataTable
        :headers="headers"
        :endpoint="endpoint"
        sortBy="numpedido"
        :sortDesc="true"
        @click="onClick"
        ref="datatable"
        search
      >
        <template v-slot:numpedido="{ row }">
          <div class="d-flex mb-n7 mt-2 ml-3">
            <v-chip
              :color="getStatusPedidoColor(row.statuspedido)"
              class="ml-auto font-weight-bold"
              small
            >
              {{ getStatusPedido(row.statuspedido) }}
            </v-chip>
          </div>
          <strong>{{ row.numpedido }}</strong> <br />
          <span>{{ row.datapedido | formatDate }}</span> <br />
          <span>Total: {{ row.totalliquido | formatMoney }}</span>
        </template>
        <template v-slot:nomecliente="{ row }">
          <strong>{{ row.nomecliente }}</strong> <br />
          <span>{{ row.emailcliente }}</span> <br />
          <span>{{ formatTelefone(row.telefonecliente) }}</span>
        </template>
        <template v-slot:nota="{ col }">
          <span v-if="col">
            <span style="font-size: 2.5em;">{{ avaliacoes[col].smile }}</span>
            <h4>{{ avaliacoes[col].text }}</h4>
          </span>
          <span v-else>Não avaliado</span>
        </template>
        <template v-slot:comentario="{ col }">
          <span v-if="col">{{ col }}</span>
          <span v-else>Sem comentário</span>
        </template>
        <template v-slot:idpedido="{ row }">
          <FormButtonMenu
            icon
            :options="tableOptions"
            :loading="loadingRow[row.numpedido]"
            @click="op => selectOption(row, op)"
          >
            <v-icon size="15">fas fa-ellipsis-v</v-icon>
          </FormButtonMenu>
        </template>
      </DataTable>
    </Panel>
  </div>
</template>

<script>
import DataTable from '@/components/DataTable'
import Panel from '@/components/Panel'
import FormButtonMenu from '@/components/form/FormButtonMenu'
import MarketingModules from '@/components/MarketingModules'
import { mapGetters } from 'vuex'
import { ModulesEnum } from '@/store/actions/modulesEnum'
import OrderStatus from '../../../utils/orderStatus'
import { PEDIDO_AVALIACOES } from '@/store/actions/pedido'

export default {
  name: 'AvaliacaoClienteTable',
  components: {
    DataTable,
    Panel,
    FormButtonMenu,
    MarketingModules
  },
  data: () => ({
    headers: [
      { text: 'Pedido', value: 'numpedido' },
      { text: 'Cliente', value: 'nomecliente' },
      { text: 'Avaliação', value: 'nota', align: 'center' },
      { text: 'Comentário do cliente', value: 'comentario', align: 'center' },
      { text: 'Opções', value: 'idpedido', clickable: false }
    ],
    tableOptions: [{ text: 'Detalhes do Pedido', value: 'detail' }],
    loadingRow: {},
    modulosForm: [
      ModulesEnum.ORCAMENTO_WEB,
      ModulesEnum.ORCAMENTO_APP,
      ModulesEnum.PAINEL_CLIENTE,
      ModulesEnum.WEB_COMMERCE,
      ModulesEnum.COMMERCE_APP
    ],
    avaliacoes: PEDIDO_AVALIACOES
  }),
  computed: {
    ...mapGetters(['getFornecedorId', 'moduloFornecedorAtivo']),
    endpoint() {
      return `/api/v4/orders/avaliations/listForTable/${this.getFornecedorId}`
    },
    showModuloForm() {
      return this.moduloFornecedorAtivo(this.modulosForm)
    }
  },
  created() {},
  methods: {
    onClick(item) {
      this.$router.push(`/fornecedor/pedidos/${item.numpedido}`)
    },
    selectOption(item, option) {
      switch (option.value) {
        case 'detail':
          return this.onClick(item)
      }
    },
    formatTelefone(phone) {
      const telefone = Array.isArray(phone) ? phone[0] : phone
      if (telefone.length === 10) {
        return `(${telefone.substring(0, 2)}) ${telefone.substring(
          2,
          6
        )}-${telefone.substring(6)}`
      } else if (telefone.length === 11) {
        return `(${telefone.substring(0, 2)}) ${telefone.substring(
          2,
          7
        )}-${telefone.substring(7)}`
      } else if (telefone.length === 13) {
        return `${telefone.substring(0, 2)} (${telefone.substring(
          2,
          4
        )}) ${telefone.substring(4, 9)}-${telefone.substring(9)}`
      }
      return telefone
    },
    getStatusPedido(status) {
      return OrderStatus && OrderStatus.OrderStatusLabel
        ? OrderStatus.OrderStatusLabel[status]
        : ''
    },
    getStatusPedidoColor(status) {
      return OrderStatus && OrderStatus.OrderStatusColor
        ? OrderStatus.OrderStatusColor[status]
        : ''
    }
  }
}
</script>

<style lang="scss" scoped></style>
