export const PEDIDO_LOAD = 'PEDIDO_LOAD'
export const PEDIDO_STATUS_TOTAL = 'PEDIDO_STATUS_TOTAL'
export const PEDIDO_DISABLE_BLOCK_PAYMENT = 'PEDIDO_DISABLE_BLOCK_PAYMENT'
export const PEDIDO_UPDATE_PAYMENT_CHECK = 'PEDIDO_UPDATE_PAYMENT_CHECK'
export const PEDIDO_UPDATE_PAYMENT_METHOD = 'PEDIDO_UPDATE_PAYMENT_METHOD'
export const PEDIDO_UPDATE_ADDRESS = 'PEDIDO_UPDATE_ADDRESS'
export const PEDIDO_GENERATE_RECEIPT = 'PEDIDO_GENERATE_RECEIPT'
export const PEDIDO_LOAD_DELIVEY_METHODS = 'PEDIDO_LOAD_DELIVEY_METHODS'
export const PEDIDO_UPDATE_DELIVEY_METHOD = 'PEDIDO_UPDATE_DELIVEY_METHOD'
export const PEDIDO_UPDATE_DELIVEY_DATE = 'PEDIDO_UPDATE_DELIVEY_DATE'
export const PEDIDO_UPDATE_RESPONSABLE = 'PEDIDO_UPDATE_RESPONSABLE'
export const PEDIDO_LOAD_LOGS = 'PEDIDO_LOAD_LOGS'
export const PEDIDO_RESEND_ERP = 'PEDIDO_RESEND_ERP'
export const PEDIDO_PAYMENT_PENDING = 'PEDIDO_PAYMENT_PENDING'
export const PEDIDO_LOG = 'PEDIDO_LOG'
export const PEDIDO_ACTIONS_INIT_DELIVERY = 'PEDIDO_ACTIONS_INIT_DELIVERY'
export const PEDIDO_ACTIONS_CONFIRM_DELIVERY = 'PEDIDO_ACTIONS_CONFIRM_DELIVERY'
export const PEDIDO_ACTIONS_ACCEPT_QUOTE = 'PEDIDO_ACCEPT_QUOTE'
export const PEDIDO_ACTIONS_SUBMIT_QUOTE = 'PEDIDO_SUBMIT_QUOTE'
export const PEDIDO_ACTIONS_INIT_SEPARATION = 'PEDIDO_INIT_SEPARATION'
export const PEDIDO_ACTIONS_CONCLUDE_SEPARATION = 'PEDIDO_CONCLUDE_SEPARATION'
export const PEDIDO_ACTIONS_CONCLUDE_WITHDRAWAL = 'PEDIDO_CONCLUDE_WITHDRAWAL'
export const PEDIDO_ACTIONS_CONFIRM_PAYMENT = 'PEDIDO_CONFIRM_PAYMENT'
export const PEDIDO_CANCEL = 'PEDIDO_CANCEL'
export const PEDIDO_AVALIATION = 'PEDIDO_AVALIATION'
export const PEDIDO_CHAT = 'PEDIDO_CHAT'
export const PEDIDO_CHAT_IMAGE = 'PEDIDO_CHAT_IMAGE'
export const PEDIDO_CONFIG = 'PEDIDO_CONFIG'
export const PEDIDO_INSERT_PRODUCT = 'PEDIDO_INSERT_PRODUCT'
export const PEDIDO_DELETE_PRODUCT = 'PEDIDO_DELETE_PRODUCT'
export const PEDIDO_UPDATE_PRODUCT = 'PEDIDO_UPDATE_PRODUCT'

export const PEDIDO_AVALIACOES = {
  pessimo: { text: 'Péssimo', smile: '😞' },
  ruim: { text: 'Ruim', smile: '😟' },
  regular: { text: 'Regular', smile: '😐' },
  bom: { text: 'Bom', smile: '😊' },
  otimo: { text: 'Ótimo', smile: '😁' }
}
